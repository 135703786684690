input[type=range] {
    height: 37px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #CCCCCC;
    border-radius: 2px;
    border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #44CCFF;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    background: #44CCFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #CCCCCC;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #CCCCCC;
    border-radius: 2px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #44CCFF;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    background: #44CCFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #CCCCCC;
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #CCCCCC;
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #44CCFF;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    background: #44CCFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #CCCCCC;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #CCCCCC;
  }